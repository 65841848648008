import React from "react"
import "./organization.css"
import {Link, graphql } from "gatsby"
import Linkify from 'react-linkify';

import {
  faExternalLinkAlt,
  faBox,
  faMapMarkerAlt,
  faBuilding,
  faEnvelopeOpenText,
  faPhone,
} from "@fortawesome/free-solid-svg-icons"
import {
  faLinkedin,
  faTwitter,
  faFacebook,
  faWhatsapp,
  faInstagram,
  faYoutube,
  faTelegram
} from "@fortawesome/free-brands-svg-icons"
import { Disqus } from 'gatsby-plugin-disqus';


import "../components/OrganizationCard"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SidebarSectionList from "../components/SidebarSectionList"
import { transformOrganization, transformNavCategories } from "../utils/airtable"
import { parseTwitterHandle } from "../utils/url"


function AttributesSection({ org, className }) {
  return (
    <SidebarSectionList title="In a snapshot" className={className}>
      {org.categories.map(category => (
        <SidebarSectionList.Item
          key={category.name}
          text={category.name}
          to={category.slug}
          icon={faBox}
        />
      ))}
      {org.location 
        ? (<SidebarSectionList.Item text={org.address} to={org.location} icon={faMapMarkerAlt} target="_blank" />) 
        : (<SidebarSectionList.Item text={org.address} icon={faMapMarkerAlt} />)
      }
      {org.orgType && (
        <SidebarSectionList.Item text={org.orgType} icon={faBuilding} />
      )}
    </SidebarSectionList>
  )
}

function SocialLinksSection({ org, className }) {
  return (
    <SidebarSectionList title="Links" className={className}>
      {org.homepage && (
        <SidebarSectionList.Item
          text="Homepage"
          href={org.homepage}
          icon={faExternalLinkAlt}
        />
      )}
      {org.is80g === 'Yes' && (
        <SidebarSectionList.Item
          text="How to avail 80G"
          href={org.how80g}
          icon={faExternalLinkAlt}
        />
      )}
      {org.youtube && (
        <SidebarSectionList.Item
          text="YouTube"
          href={org.youtube}
          icon={faYoutube}
        />
      )}
      {org.instagram && (
        <SidebarSectionList.Item
          text="Instagram"
          href={org.instagram}
          icon={faInstagram}
        />
      )}
      {org.twitter && (
        <SidebarSectionList.Item
          text={parseTwitterHandle(org.twitter) || "Twitter"}
          href={org.twitter}
          icon={faTwitter}
        />
      )}
      {org.facebook && (
        <SidebarSectionList.Item
          text="Facebook"
          href={org.facebook}
          icon={faFacebook}
        />
      )}
      {org.telegram && (
        <SidebarSectionList.Item
          text="Telegram"
          href={org.telegram}
          icon={faTelegram}
        />
      )}
    </SidebarSectionList>
  )
}

function ContributionSection({ data, org: { contact: { email, phone, whatsapp } }, className }) {
  return (
    <SidebarSectionList title="Contact " className={className}>
      <SidebarSectionList.Item
        text={email}
        icon={faEnvelopeOpenText}
      />
      {phone &&(
      <SidebarSectionList.Item
        text={phone}
        icon={faPhone}
      />
  )}
      {whatsapp && (
        <SidebarSectionList.Item
          text={whatsapp}
          icon={faWhatsapp}
        />
      )}
    </SidebarSectionList>
  )
}
/* Donate via UPI or International Bank Transfer*/
// const DonateSection = ({ donateType, text  }) => (
//   <>
//     <p className='my-2'>
//       <span className='font-medium'>Donate {donateType}:</span> <br/>
//       <p className='text-blue-600 hover:text-red-700'>{text}</p>
//       </p>
//   </>
// )
const componentDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank" className="text-blue-600 hover:text-red-700">
    {text}
  </a>
);

/* Donate via Third Party*/
const DonateSection = ({ donateType, text  }) => (
  <>
    <p className='my-2'>
      <span className='font-medium'>Donate {donateType}:</span> <br/>
      <span className='text-blue-600'>
      <Linkify  componentDecorator={componentDecorator}>{text}</Linkify>
      </span>
      </p>
  </>
)
/* Donate via Bank Transfer */
const DonateBTSection = ({ donateType, text  }) => (
  <>
    <p className='my-2'>
      <span className='font-medium'>Donate {donateType}:</span> <br/>
      <pre>{text}</pre>
      </p>
  </>
)


export default function OrganizationTemplate({ data }) {
  const siteTitle = data.site.siteMetadata.title
  const categories = transformNavCategories(data.categories.nodes)
  const org = transformOrganization(data.organization, categories)

  console.log(org)

  const { contributorEmail, contributorName } = org.contributor
  const { donateBankTrans, donateIntFundTrans, donateThirdParty, donateUPI } = org.donate

  const disqusConfig = {
    url: `https://donatefordharma.org${org.slug}`,
    identifier: org.slug,
    title: org.Name,
  }
    
  return (
    <Layout contentClassName="bg-gray-100 font-sans">
      <SEO
        title={`${org.title} on ${siteTitle}`}
        description={org.tagline}
        imageUrl={org.LogoURL?.src}
        // imageUrl={org.logo?.src}
      />

      <div className="max-w-4xl mx-auto lg:pt-8 pb-4 p-4 xs:p-8 lg:p-0">
        <div className="mb-10 md:mt-6 flex flex-col lg:flex-row">
          <div className="w-5/5 lg:w-3/5">
            <div className="flex">
              {org.logo && (
                <div className="mr-5 w-24 flex-shrink-0 ">
                  <img
                    // src={org.logo .raw[0].url}
                    src={org.LogoURL}
                    className="OrganizationCard-logo blend-multiply rounded-lg w-24 h-24 object-contain "
                    alt={`${org.title} logo`}

                  />
                </div>
              )}
              <div className="mr-2">
                <h1 className="flex-grow text-3xl font-semibold text-yellow-600">{org.title}</h1>
                <p>{org.tagline}</p>
              </div>
            </div>
            {org.about && org.about !== org.tagline && (
              <div className="my-6">{org.about}</div>
            )}
            <hr className='mt-6 text-blue-400'/>
            <br/>
            {org.displayDonateSection && (
              <>
              <h1 className="flex-grow text-xl font-semibold text-green-700">How to Donate ?</h1>
              <div className='mt-3'> 
                {donateUPI && (
                  <DonateSection donateType='via UPI' text={donateUPI} />
                )}
                {donateIntFundTrans && (
                  <DonateSection donateType='via International Fund Transfer' text={donateIntFundTrans} />
                )}
                {donateBankTrans && (
                  <DonateBTSection donateType='via Bank Transfer' text={donateBankTrans} />
                )}
                {donateThirdParty && (
                  <DonateSection donateType={'via Third-Party'} text={donateThirdParty} />
                )}
              </div>
              </>
            )}
            <hr className='mt-6'/>
            <div className='flex mt-6 '>
              <div className='text-justify font-light text-xs w-36 text-green-800'>
                <p>Last Updated: <span className='opacity-30'>{org.lastUpdated}</span></p>
                <p>Contributed By: <span className='opacity-30'>{contributorName}</span></p>
                {contributorEmail && (
                  <p>Contact: <span className='opacity-30'>{contributorEmail}</span></p>
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-col w-5/5 lg:w-2/5 lg:pl-16 items-center mt-3 lg:mt-0">
            <div className="sidebar-sections-container w-full flex flex-col sm:flex-row lg:flex-col justify-start justify-around lg:justify-start">
              <AttributesSection org={org} className="flex flex-col mb-8" />
              <SocialLinksSection org={org} className="flex flex-col mb-8" />
              <ContributionSection
                org={org}
                className="flex flex-col mb-8"
                data={data}
              />
            </div>
          </div>
        </div>
        <Disqus {...disqusConfig} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query OrganizationPageQuery($id: String)  {
    site {
      siteMetadata {
        title
      }
    }

    organization: airtable(table: {eq: "Org_Data"}, id: {eq: $id}){
      data {
        About
        Address
        Categories
        Donate_Bank_Trans
        Donate_Int_Fund_Trans
        Donate_Third_Party
        Donate_UPI
        Email
        Email_Contributor
        How80g
        Is80g
        Logo {
          raw {
            url
          }
        }
        Maps
        Name
        Name_Contributor
        Orgtype
        Phone
        Tagline
        Whatsapp
        Website
        Timestamp(fromNow: true)
        youtube
        facebook
        instagram
        twitter
        telegram
        LogoURL
      }
      recordId
      id
    }

    categories: allAirtable(filter: {table: {eq: "Categories"}}) {
      nodes {
        id
        data {
          cat_name
          Org_Data
        }
        recordId
      }
    }
    
  }
`